/* eslint @nx/enforce-module-boundaries: 'warn' */
import React, { ReactText, useCallback, useEffect, useMemo, useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { ModalPopup, PopupDialogOption } from '@common/toolbox';

export function usePopupDialog() {
  const { i18n } = useTranslation();
  const awaitingPromiseRef = React.useRef<{
    resolve: (id: ReactText) => void;
    reject: () => void;
  }>();

  const [option, setOption] = useState<PopupDialogOption>();

  const close = () => {
    awaitingPromiseRef.current?.resolve('close');
    setOption(undefined);
  };

  const handleSubmit = useCallback((id: ReactText) => {
    awaitingPromiseRef.current?.resolve(id);
    close();
  }, []);

  const buttons = useMemo(() => {
    return option?.buttons?.map((value, index) => ({
      ...value,
      id: value?.id ?? String(index),
      onClick: () => {
        value.onClick?.();
        handleSubmit(value.id ?? index);
      },
    }));
  }, [handleSubmit, option?.buttons]);

  const wrapper = useMemo(
    () => (typeof document !== 'undefined' ? document.body.appendChild(document.createElement('div')) : null),
    [],
  );

  const dispose = useCallback(() => {
    if (!wrapper) {
      return;
    }
    setTimeout(() => {
      unmountComponentAtNode(wrapper);
      setTimeout(() => {
        if (document.body.contains(wrapper)) {
          document.body.removeChild(wrapper);
        }
      });
    });
  }, [wrapper]);

  const handleOutsideClick = useCallback(() => {
    if (option?.canBackdropResolve) {
      awaitingPromiseRef.current?.resolve('skip');
    }
    close();
  }, [option?.canBackdropResolve]);

  const active = useMemo(() => {
    return Boolean(option);
  }, [option]);

  const dialog = useMemo(
    () => (
      <I18nextProvider i18n={i18n}>
        <ModalPopup {...{ ...option, buttons, active, handleOutsideClick, close }} />
      </I18nextProvider>
    ),
    [option, buttons, active, handleOutsideClick, i18n],
  );

  const open = (open_option: PopupDialogOption) => {
    setOption(open_option);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  useEffect(() => {
    render(dialog, wrapper);
  }, [dialog, wrapper]);

  useEffect(() => {
    return () => {
      dispose();
    };
  }, [dispose]);

  return { open, close };
}

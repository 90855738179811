import React, { ReactNode, useEffect, useState } from 'react';
import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { AnimatePresence, createDomMotionComponent } from 'framer-motion';

import { Gray_Light_400, Gray_Light_900, Gray_Light_White, Typography, web_mobile_width } from '@common/styles';
import { ScrollLock } from '@common/utils';

import { RoundFilledButton, RoundFilledButtonProps } from '../Button/RoundFilled/RoundFilled';

export interface ButtonOption extends RoundFilledButtonProps {
  id?: string;
  onClick?: () => void;
  is_fixed?: boolean;
}

export interface PopupDialogOption {
  image?: ReactNode;
  title?: ReactNode;
  text?: ReactNode;
  is_product_popup?: boolean;
  /**
   * RoundFillButton props를 입력받습니다.
   * 버튼 클릭 시 지정한 id 또는 index를 리턴합니다.
   */
  buttons?: ButtonOption[];
  disableBackdropClick?: boolean;
  canBackdropResolve?: boolean;
  radius?: boolean;
  cancel_text?: ReactNode;
  /**
   * Body 스타일 관련 props를 입력받습니다.
   */
  body_padding?: string;
  title_color?: string;
  text_color?: string;
  /**
   * @description window scroll을 할 수 있게 하는지 여부
   * @default false
   */
  canScroll?: boolean;
  containerStyle?: SerializedStyles;
  wrapperStyle?: SerializedStyles;
}

export interface ModalPopupProps extends PopupDialogOption {
  active?: boolean;
  close?: () => void;
  handleOutsideClick?: () => void;
}

export function ModalPopup({
  image,
  title,
  text,
  buttons,
  disableBackdropClick,
  cancel_text,
  radius,
  active,
  canScroll,
  close,
  containerStyle,
  wrapperStyle,
  handleOutsideClick,
  body_padding = '24px 16px',
  title_color = Gray_Light_900,
  text_color = Gray_Light_400,
  is_product_popup = false,
}: ModalPopupProps) {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!containerRef) {
      return;
    }
    const bodyLock = new ScrollLock(containerRef);

    if (Boolean(canScroll) === false && Boolean(active) === true) {
      bodyLock.disableScroll();
    } else {
      bodyLock.enableScroll();
    }
    return () => {
      bodyLock.enableScroll();
    };
  }, [containerRef]);

  return (
    <AnimatePresence>
      {active && (
        <Container css={containerStyle}>
          <Overlay
            animate={{ background: 'rgba(73, 80, 87, 0.7)' }}
            {...(!disableBackdropClick && { onClick: handleOutsideClick })}
          />
          <Wrapper
            ref={setContainerRef}
            is_product_popup={is_product_popup}
            css={[{ ...(radius && { borderRadius: '8px 8px 0px 0px' }) }, wrapperStyle]}
          >
            {image && image}
            <Body padding={body_padding}>
              {title && (
                <Title title_color={title_color} className={Typography.HEAD_20_BOLD}>
                  {title}
                </Title>
              )}
              {text && (
                <Text text_color={text_color} className={Typography.BODY_15_MEDIUM}>
                  {text}
                </Text>
              )}
              {buttons && (
                <ButtonWrapper>
                  {buttons.map((button_props) => (
                    <StyledRoundFilledButton
                      is_fixed={button_props?.is_fixed ?? true}
                      key={button_props.id}
                      size={button_props?.size ?? 'lg'}
                      {...button_props}
                    />
                  ))}
                </ButtonWrapper>
              )}
              {cancel_text && (
                <CancelButton className={Typography.BODY_13_REGULAR} onClick={close}>
                  {cancel_text}
                </CancelButton>
              )}
            </Body>
          </Wrapper>
        </Container>
      )}
    </AnimatePresence>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 910;
`;

const Overlay = styled(createDomMotionComponent('div'))`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

const Wrapper = styled(createDomMotionComponent('div'))<{ is_product_popup: boolean }>`
  box-sizing: border-box;
  position: relative;
  margin: auto;
  border-radius: 14px;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 15px;
  background-color: ${Gray_Light_White};
  width: calc(100% - 32px);
  min-width: 288px;
  max-width: 343px;
  text-align: center;
  overflow: hidden;
  z-index: 202;

  ${({ is_product_popup }) =>
    is_product_popup &&
    `
    max-width: 263px;
    @media screen and (min-width: ${web_mobile_width}px) {
    max-width: 394px;
  }
  `};
`;

const Body = styled.div<{ padding: string }>`
  padding: ${({ padding }) => padding};
`;

const Title = styled.div<{ title_color: string }>`
  color: ${({ title_color }) => title_color};
  margin: 0 0 8px;
  white-space: pre-wrap;
`;

const Text = styled.div<{ text_color: string }>`
  color: ${({ text_color }) => text_color};
  white-space: pre-wrap;
`;

const ButtonWrapper = styled.div`
  margin: 16px 0 0;
  & > * {
    margin: 8px 0 0;
  }
`;

const StyledRoundFilledButton = styled(RoundFilledButton, { shouldForwardProp: (prop) => prop !== 'is_fixed' })<{
  is_fixed: boolean;
}>`
  ${({ is_fixed }) =>
    is_fixed &&
    `
    display: inline-block;
    width: 100%;
  `}
`;

const CancelButton = styled.button`
  margin: 16px 0 0;
  padding: 4px 12px;
  color: ${Gray_Light_400};
  text-align: center;
  height: 24px;
`;

import React, { forwardRef, memo, useMemo } from 'react';
import {
  Gray_Light_050,
  Gray_Light_100,
  Gray_Light_150,
  Gray_Light_200,
  Gray_Light_400,
  Gray_Light_900,
  Gray_Light_950,
  Pink_Light_100,
  Pink_Light_400,
  Pink_Light_600,
  Static_Black,
  Static_White,
} from '@croquiscom/zds';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import Link, { LinkProps } from 'next/link';

import { Typography } from '@common/styles';
export type RoundFilledButtonSize = 'sm' | 'md' | 'lg';
export type RoundFilledButtonStatus = 'pressed' | 'normal' | 'disabled';
export type RoundFilledVariant = 'default' | 'primary' | 'second' | 'third';
export interface RoundFilledButtonProps
  extends Omit<React.HTMLAttributes<HTMLButtonElement & HTMLAnchorElement>, 'shallow'>,
    Omit<LinkProps, 'href' | 'onMouseEnter' | 'onClick' | 'onTouchStart'> {
  /** @default 'md' */
  size?: RoundFilledButtonSize;
  /**
   * @default 'normal'
   */
  status?: RoundFilledButtonStatus;
  /** @default 'primary' */
  variant?: RoundFilledVariant;
  /**
   * href가 있으면 a 태그 역할을 합니다.
   */
  href?: string;
  /**
   * @deprecated :active 스타일 변경을 금지한다.
   */
  disable_active?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  $shallow?: 'true' | 'false';
}

const styleBySize = (size?: RoundFilledButtonSize) => {
  switch (size) {
    case 'sm':
      return css`
        padding: 6px 12px;
        min-height: 32px;
        min-width: 48px;
      `;
    case 'lg':
      return css`
        padding: 14px 24px;
        min-height: 50px;
        min-width: 74px;
      `;
    case 'md':
    default:
      return css`
        padding: 10px 16px;
        min-height: 42px;
        min-width: 68px;
      `;
  }
};

const typographyBySize = (size?: RoundFilledButtonSize) => {
  switch (size) {
    case 'lg':
      return Typography.BODY_15_BOLD;
    case 'md':
      return Typography.BODY_15_BOLD;
    case 'sm':
    default:
      return Typography.BODY_13_SEMIBOLD;
  }
};

const switchByVariant = (variant?: RoundFilledButtonStatus) => (pressed: string, normal: string, disabled: string) => {
  switch (variant) {
    case 'disabled':
      return disabled;
    case 'pressed':
      return pressed;
    case 'normal':
    default:
      return normal;
  }
};

const colorByTypeVariant = (type?: RoundFilledVariant, variant?: RoundFilledButtonStatus, disable_active?: boolean) => {
  const switchColor = switchByVariant(variant);
  switch (type) {
    case 'default':
      return css`
        background: ${switchColor(Static_White, Static_White, Gray_Light_200)};
        border: 1px solid ${switchColor(Gray_Light_200, Gray_Light_200, Gray_Light_200)};
        color: ${switchColor(Gray_Light_900, Gray_Light_900, Gray_Light_200)};
        &:link,
        &:active,
        &:visited {
          color: ${switchColor(Gray_Light_900, Gray_Light_900, Gray_Light_200)};
        }
        &:active {
          background: ${disable_active ? Static_White : Static_White};
        }
      `;
    case 'second':
      return css`
        background: ${switchColor(Pink_Light_600, Pink_Light_400, Pink_Light_100)};
        color: ${switchColor(Static_White, Static_White, Static_White)};
        &:link,
        &:active,
        &:visited {
          color: ${switchColor(Static_White, Static_White, Static_White)};
        }
        &:active {
          background: ${disable_active ? Pink_Light_100 : Pink_Light_600};
        }
      `;
    case 'third':
      return css`
        background: ${switchColor(Static_White, Static_White, Static_White)};
        color: ${switchColor(Gray_Light_400, Gray_Light_400, Gray_Light_200)};
        &:link,
        &:active,
        &:visited {
          color: ${switchColor(Gray_Light_400, Gray_Light_400, Gray_Light_200)};
        }
        &:active {
          background: ${!disable_active && Gray_Light_100};
        }
      `;
    case 'primary':
    default:
      return css`
        background: ${switchColor(Static_Black, Gray_Light_950, Gray_Light_200)};
        color: ${switchColor(Gray_Light_050, Gray_Light_050, Gray_Light_050)};
        &:link,
        &:active,
        &:visited {
          color: ${switchColor(Gray_Light_050, Gray_Light_050, Gray_Light_050)};
        }
        &:active {
          background: ${disable_active ? Gray_Light_200 : Static_Black};
        }
      `;
  }
};

const default_style = css`
  border-radius: 24px;
  white-space: pre;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

/** @public */
export const RoundFilledButton = memo(
  forwardRef<HTMLButtonElement & HTMLAnchorElement, RoundFilledButtonProps>(
    ({ className, size, status, variant, href, ...props }, ref) => {
      const typography = useMemo(() => typographyBySize(size), [size]);
      const padding = useMemo(() => styleBySize(size), [size]);
      const color = useMemo(() => {
        const style_status = props.disabled ? 'disabled' : status;
        return colorByTypeVariant(variant, style_status, props.disabled);
      }, [props.disabled, status, variant]);
      const classNames = useMemo(() => cx(typography, className), [className, typography]);
      if (href) {
        const { as, replace, scroll, passHref, prefetch, shallow, locale, $shallow, ...rest_props } = props;
        return (
          <Link
            href={href}
            {...{
              as,
              replace,
              scroll,
              shallow: typeof shallow === 'boolean' ? shallow : $shallow === 'true',
              passHref,
              prefetch,
              locale,
            }}
          >
            <a
              {...rest_props}
              ref={ref}
              className={classNames}
              css={css`
                ${default_style}
                ${padding}
              ${color}
              `}
            >
              {rest_props.children}
            </a>
          </Link>
        );
      }
      return (
        <button
          {...props}
          className={classNames}
          css={css`
            ${default_style}
            ${padding}
          ${color}
          `}
          ref={ref}
        />
      );
    },
  ),
);

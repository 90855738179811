const isBrowser = typeof window !== 'undefined';
const isIOS =
  isBrowser &&
  window.navigator.platform &&
  (/iP(ad|hone|od)/.test(window.navigator.platform) ||
    (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1));

/** passive를 지원안하는 브라우저를 위한 체크 로직
 * https://developer.mozilla.org/ko/docs/Web/API/EventTarget/addEventListener#passive_%EB%A6%AC%EC%8A%A4%EB%84%88%EB%A1%9C_%EC%8A%A4%ED%81%AC%EB%A1%A4%EB%A7%81_%EC%84%B1%EB%8A%A5_%ED%96%A5%EC%83%81 */
/* Feature detection */
let passiveIfSupported: boolean | { passive: boolean } = false;

try {
  if (isBrowser) {
    window.addEventListener(
      'test',
      () => {
        return;
      },
      Object.defineProperty({}, 'passive', {
        // eslint-disable-next-line getter-return
        get: () => {
          passiveIfSupported = { passive: true };
        },
      }),
    );
  }
} catch (err) {
  console.error(err);
}

export class ScrollLock {
  scrollTarget: HTMLElement | null = null;
  modalContainer: HTMLElement | null = null;
  startX: number | null = null;
  startY: number | null = null;

  constructor(modalContainer: HTMLElement | null) {
    this.modalContainer = modalContainer;
  }

  handleTouchStart(event: TouchEvent) {
    // 손가락 한개 이상 터치할 경우는 스크롤 제스쳐가 아님
    if (event.touches.length > 1) {
      return;
    }

    const container = this.modalContainer;
    let scrollTarget = event.target as HTMLElement;

    while (scrollTarget instanceof HTMLElement && scrollTarget !== container) {
      const style = window.getComputedStyle(scrollTarget);
      if (
        (scrollTarget.scrollHeight > scrollTarget.clientHeight ||
          scrollTarget.scrollWidth > scrollTarget.clientWidth) &&
        /auto|scroll/.test(style.overflow)
      ) {
        break;
      }
      scrollTarget = scrollTarget.parentNode as HTMLElement;
    }

    this.scrollTarget = scrollTarget;
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;
  }

  handleTouchMove(event: TouchEvent) {
    // 손가락 한개 이상 터치할 경우는 스크롤 제스쳐가 아님
    if (event.touches.length > 1) {
      return;
    }

    if (this.startX == null || this.startY == null) {
      return;
    }
    const diffX = event.touches[0].clientX - this.startX;
    const diffY = event.touches[0].clientY - this.startY;
    const { scrollTarget } = this;
    if (scrollTarget == null) {
      return;
    }
    // 만약 유저가 움직이는 방향이 스크롤 타겟을 스크롤할 수 있는 방향이 아니라면, body가 스크롤 될 것이기 때문에 preventDefault를 실행합니다.
    const scrollingUp = scrollTarget.scrollTop > 0 && diffY > 0;
    const scrollingDown = scrollTarget.scrollHeight - scrollTarget.scrollTop > scrollTarget.clientHeight && diffY < 0;
    const scrollingLeft = scrollTarget.scrollLeft > 0 && diffX > 0;
    const scrollingRight = scrollTarget.scrollWidth - scrollTarget.scrollLeft > scrollTarget.clientWidth && diffX < 0;
    if (!scrollingUp && !scrollingDown && !scrollingLeft && !scrollingRight) {
      event.preventDefault();
    }
  }

  disableScroll() {
    if (!isBrowser) {
      return;
    }
    document.body.style.overflow = 'hidden';
    if (isIOS) {
      document.body.addEventListener('touchstart', this.handleTouchStart, passiveIfSupported);
      document.body.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    }
  }

  enableScroll() {
    if (!isBrowser) {
      return;
    }
    document.body.style.removeProperty('overflow');
    if (isIOS) {
      document.body.removeEventListener('touchstart', this.handleTouchStart);
      document.body.removeEventListener('touchmove', this.handleTouchMove);
    }
  }
}

/* eslint @nx/enforce-module-boundaries: 'warn' */
import { css } from '@emotion/react';

import { apple_store_link, play_store_link, web_path } from '@common/constants';
import { isAndroid, isIOS, isMobile } from '@common/device-manager';
import { Photo, PopupDialogOption, TransPlainText } from '@common/toolbox';

import { BottomDialogOption } from '../dump/BottomDialog';
import { useBottomDialog } from '../useBottomDialog';
import { usePopupDialog } from '../usePopupDialog';
import deskQrPng from './images/desk-qr.png';
import krPromotionEventQrPng from './images/kr-promotion-event-qr.png';
import krPromotionPdpQrPng from './images/kr-promotion-pdp-qr.png';
import landingPopupSrc from './images/landing-popup.png';
import signUpSuccessPng from './images/sign-up-success.png';
export const SIGN_OUT_LANDING_LINK = 'https://zigzag.onelink.me/4107287618/b371c97f';
export const HOME_LANDING_LINK_FORM_WEB_HOME = {
  KR: 'https://zigzag.onelink.me/4107287618/hxzdlxd7',
};

export const HOME_LANDING_LINK = {
  KR: 'https://zigzag.onelink.me/4107287618/52b4c05c',
  JP: 'https://zigzag.onelink.me/Rhv9/d2khpna4',
  US: 'https://zigzag.onelink.me/cQuc/u8r9xyq4',
  CA: 'https://zigzag.onelink.me/cQuc/f0fue85i',
};
export const MobileLandingPopup = (landing_link = HOME_LANDING_LINK.KR): PopupDialogOption => ({
  image: <Photo src={landingPopupSrc} width={343} height={212} objectfit='cover' />,
  title: <TransPlainText ns='common' i18nKey={'해당 기능은\n앱에서 사용 가능해요!'} />,
  text: <TransPlainText ns='common' i18nKey='지그재그 앱에서 확인해보세요.' />,
  buttons: [
    {
      children: <TransPlainText ns='common' i18nKey='앱에서 보기' />,
      onClick: () => {
        window.open(landing_link, '_blank');
      },
    },
  ],
  cancel_text: <TransPlainText ns='common' i18nKey='그냥 웹으로 보기' />,
});

export const DeskTopLandingPopup = (): PopupDialogOption => ({
  image: <Photo src={landingPopupSrc} width={343} height={212} objectfit='cover' />,
  title: <TransPlainText ns='common' i18nKey={'해당 기능은\n앱에서 사용 가능해요!'} />,
  text: <TransPlainText ns='common' i18nKey='지그재그 앱에서 확인해보세요.' />,
  buttons: [{ children: <TransPlainText ns='common' i18nKey='확인' /> }],
});

export const useLandingPopup = (landing_link?: string) => {
  const { open: openPopup, close } = usePopupDialog();

  return {
    open: () => openPopup(isMobile() ? MobileLandingPopup(landing_link) : QrLandingPopup()),
    close,
  };
};
export const QrLandingPopup = (): PopupDialogOption => ({
  image: <Photo src={deskQrPng} width={343} height={212} objectfit='cover' />,
  title: <TransPlainText ns='common' i18nKey={'지그재그 앱에서 더 많은\n혜택을 받아보세요!'} />,
  text: <TransPlainText ns='common' i18nKey={'스마트폰으로 카메라 앱을 켜서\nQR코드를 포커스해보세요.'} />,
  buttons: [{ children: <TransPlainText ns='common' i18nKey='확인' /> }],
  canBackdropResolve: true,
  disableBackdropClick: true,
});

export const MobileBenefitLandingPopup = (landing_link = HOME_LANDING_LINK.KR): PopupDialogOption => ({
  image: <Photo src={landingPopupSrc} width={343} height={212} objectfit='cover' />,
  title: '지그재그 앱에서 더 많은\n혜택을 받아보세요!',
  buttons: [
    {
      children: '앱에서 보기',
      onClick: () => {
        window.location.assign(landing_link);
      },
    },
  ],
  cancel_text: '그냥 웹으로 보기',
  canBackdropResolve: true,
  disableBackdropClick: true,
});

export const useBenefitLandingPopup = () => {
  const { open: openPopup, close } = usePopupDialog();

  return {
    open: () => openPopup(isMobile() ? MobileBenefitLandingPopup() : QrLandingPopup()),
    close,
  };
};
export const SignUpPopup = (message: string | null): PopupDialogOption => ({
  image: <Photo src={signUpSuccessPng} width={343} height={212} />,
  text: message ?? <TransPlainText ns='auth' i18nKey='지그재그에서 편리한 쇼핑하세요!' />,
  title: <TransPlainText ns='auth' i18nKey='지그재그 가입을 축하드려요' />,
  canBackdropResolve: true,
  buttons: [{ children: '확인' }],
});

export const BookmarkCouponPopup = (landing_link = HOME_LANDING_LINK.KR): PopupDialogOption => ({
  image: <Photo src={landingPopupSrc} width={343} height={212} />,
  title: '즐겨찾기 쿠폰은\n앱에서 다운받을 수 있어요!',
  text: '지그재그 앱에서 확인해보세요.',
  buttons: [
    {
      children: '앱에서 보기',
      onClick: () => {
        window.location.assign(landing_link);
      },
    },
  ],
  cancel_text: '닫기',
});

export const ReviewPopup = (landing_link = HOME_LANDING_LINK.KR): PopupDialogOption => ({
  image: <Photo src={landingPopupSrc} width={343} height={212} />,
  title: '리뷰 작성은\n앱에서 할 수 있어요!',
  text: '지그재그 앱에서 확인해보세요.',
  buttons: [
    {
      children: '앱에서 보기',
      onClick: () => {
        window.location.assign(landing_link);
      },
    },
  ],
  cancel_text: '닫기',
});

export const LatestAppVersionPopup = (): PopupDialogOption => {
  return {
    image: <Photo src={landingPopupSrc} width={343} height={212} />,
    title: <TransPlainText ns='products' i18nKey='앱을 업데이트 해주세요.' />,
    text: <TransPlainText ns='products' i18nKey='이전 버전에서는 이용하실 수 없습니다.' />,
    buttons: [
      {
        children: <TransPlainText ns='common' i18nKey='업데이트 하기' />,
        onClick: () => {
          if (isIOS()) {
            window.location.assign(apple_store_link);
          } else if (isAndroid()) {
            window.location.assign(play_store_link);
          }
        },
      },
    ],
  };
};

const InvalidSignUpDialog = (): BottomDialogOption => ({
  text: <TransPlainText ns='common' i18nKey='잘못된 접근입니다.' />,
  buttons: [
    {
      children: <TransPlainText ns='common' i18nKey='확인' />,
      onClick: () => {
        window.location.assign(web_path.auth.login);
      },
    },
  ],
  disableBackdropClick: true,
});

export const useInvalidSignUpDialog = () => {
  const { open: openBottomDialog, close } = useBottomDialog();
  return {
    open: async () => {
      await openBottomDialog(InvalidSignUpDialog());
    },
    close,
  };
};

export const KrPromotionEventQrLandingPopup = (): PopupDialogOption => {
  const krPromotionLayoutConcernedStyle = css`
    z-index: 1000;
  `;
  return {
    image: <Photo src={krPromotionEventQrPng} width={343} height={212} objectfit='cover' />,
    title: <TransPlainText ns='common' i18nKey={'지그재그 앱에서 더 많은\n혜택을 받아보세요!'} />,
    text: <TransPlainText ns='common' i18nKey={'스마트폰으로 카메라 앱을 켜서\nQR코드를 포커스해보세요.'} />,
    buttons: [{ children: <TransPlainText ns='common' i18nKey='확인' /> }],
    canBackdropResolve: true,
    containerStyle: krPromotionLayoutConcernedStyle,
  };
};

export const KrPromotionPdpQrLandingPopup = (): PopupDialogOption => {
  const krPromotionLayoutConcernedStyle = css`
    z-index: 1000;
  `;
  return {
    image: <Photo src={krPromotionPdpQrPng} width={343} height={212} objectfit='cover' />,
    title: <TransPlainText ns='common' i18nKey={'지그재그 앱에서 더 많은\n혜택을 받아보세요!'} />,
    text: <TransPlainText ns='common' i18nKey={'스마트폰으로 카메라 앱을 켜서\nQR코드를 포커스해보세요.'} />,
    buttons: [{ children: <TransPlainText ns='common' i18nKey='확인' /> }],
    canBackdropResolve: true,
    containerStyle: krPromotionLayoutConcernedStyle,
  };
};
